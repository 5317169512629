export const TRANSLATIONS_DK = {
    // Function with plural state, så that we have only one and more than one
    // {t('date_format_two', { date: new Date() })} ={' '}
    // {t('keyWithCount', { count: 1 })}
    // keyWithCount: 'Anne has bought {{count}} book',
    // keyWithCount_plural: 'Anne has bought {{count}} books'

    /**  
     * 
     * Site Settings
     * 
     */
    DateFormat: '{{date, DD.MM.YYYY}}',
    CurrencyFormat: '{{currency}} kr',

    JoinOurDiscord: 'Tilmeld dig Discord',
    JoinOurFacebook: 'Tilmeld dig Facebook',
    SiteSettings: 'Side Indstillinger',
    LoadingPayment: 'Forbinder til PayPal...',
    LoadingServers: 'Henter servere...',
    LoadingContent: 'Henter indhold...',
    Loading: 'Henter siden...',

    /**  
     * 
     * Site Language
     * 
     */
    Yes: 'Ja',
    No: 'Nej',
    You: 'Dig',
    From: 'Fra',
    Profile: 'Profil',
    Payments: 'Betalinger',
    Settings: 'Indstillinger',
    Support: 'Support',
    Logout: 'Log ud',
    Login: 'Log Ind',
    LoginToPay: 'Log ind for at købe',
    AvailableGames: 'Tilgængelige Spil',
    ConfigurationServer: 'Server Konfiguration',
    ConfigurationOptions: 'Konfigurations Muligheder',
    Configuration: 'Konfiguration',
    ClientArea: 'Bruger Portal',
    GameServers: 'Servere',
    ServerName: 'Server Navn',
    PanelName: 'Panel Navn',
    ChooseAServerName: 'Vælg et server navn',
    ChooseGameType: 'Vælg spil type',
    ChooseServerType: 'Vælg en spil type',
    GameType: 'Mulige Spil Typer',
    AllServers: 'Alle servere',
    ServerStatus: 'Server Status',
    OrderNow: 'Bestil nu',
    ServersFrom: 'Servere fra',
    ServerSettings: 'Server Opsætning',
    AMonth: 'Måned',
    PrMonth: 'Pr. Måned',
    MonthlySubscription: 'Automatisk fornyelse hver måned',
    FirstMonth: 'Første Måned',
    Invoice: 'Kvittering',
    CreditBefore: 'Konto',
    CreditLeft: 'Konto efter køb',
    Account: 'Konto',
    ChargeAccount: 'Ikke nok kredit',
    ChargeHere: 'Påfyld her',
    ChargeCreditHere: 'Påfyld din konto',
    ChargeCreditHereMsg: 'Her kan du optanke din konto.',
    PaymentMethods: 'Betalings muligheder',
    ChargeAmount: 'Påfyld beløb',
    YourCredit: 'Din konto',
    Credit: 'Nuværende konto',
    StockLeft: 'Server tilbage',
    StockLeft_Plural: 'Servere tilbage',
    ForgotMsg: 'Har du glemt din adgangskode ?',
    ForgotBtn: 'Send gendannelses link',
    DoesNotExist: 'Ops, findes ikke!',
    DoesNotExistMsg: 'Det produkt du leder efter findes ikke, eller eksistere desværre ikke længere. Vælg menu punktet \'Spille Servere, her finder du en liste over alle vores produkter.',
    DiscountMsg: 'Tilbud',
    Phone: 'Telefon',
    Timezone: 'Tidszone',
    Updates: 'Opdateringer',
    UserMenu: 'Bruger Menu',
    ServerPanel: 'Server panel',
    Unothorized: 'Uautoriseret adgang',
    Amount: 'Antal',
    Date: 'Dato',
    Status: 'Status',
    CreateOne: 'Opret en her',
    CreateTicket: 'Opret ticket',
    CreateSupportTicket: 'Opret Support Ticket',
    DifferentGames: 'Forskellige Spil',
    HostedServers: 'Spil Spillet',
    HappyCustomers: 'Spillere Oprettet',
    StartYourServerNow: 'Start din server nu',
    ItemsPrPage: 'Pr Side',
    SharedHosting: 'Shared Hosting',
    ServerLocation: 'Server Placering',

    Total: 'Total',
    Tax: 'Moms',
    Price: 'Pris',
    Payment: 'Betaling',
    BuyServer: 'Køb Server',
    PayHere: 'Tilføj mere tid her',
    Included: 'Inkluderet',
    Extra: 'Ekstra',
    Available: 'Tilgængelige',
    Options: 'Muligheder',

    DDoSProtection: 'DDoS Beskyttelse',
    CPUUsed: 'AMD Ryzen 5 3600',
    OneClickSetup: 'Hurtig Opsætning',
    InstantDelivery: 'Øjeblikkelig Levering',
    Packages: 'Pakker',

    FAQ: 'F.A.Q',
    FrequentlyAskedQuestions: 'Ofte Stillede Spørgsmål',
    TermsOfService: 'Servicevilkår',
    PrivacyPolicy: 'Privatlivs Politik',
    UsefulLinks: 'Nyttige Links',
    SiteDescription: 'Vi er et lille hosting firma, der levere lynende hurtige servere, til dig eller dit fællesskab. Bygget af gamere, til gamere.',

    Home: 'Forside',
    Overview: 'Oversigt',

    SignInWidth: 'Log ind med',
    SignUpWidth: 'Opret med',
    Or: 'eller',

    Email: 'Email',
    Username: 'Brugernavn',
    Password: 'Adgangskode',
    NewPassword: 'Ny Adgangskode',
    RepeatPassword: 'Gentag Adgangskode',
    ChangePassword: 'Ændre adgangskoden',
    ChangeYourPassword: 'Ændring af adgangskode',
    RememberMe: 'Husk mig',
    ForgotPassword: 'Glemt adgangskode',
    Register: 'Registrer',
    RegisterHere: 'Registrer her',
    NotAMember: 'Ikke medlem',
    SignIn: 'Log ind',

    AgreeTermsStart: 'Jeg har læst og accepteret jeres',
    AgreeTermsEnd: 'Og ',

    EmailNote1: '3 til 30 tegn.',
    EmailNote2: 'Bogstaver, tal, understreg og bindestreg er tilladt',
    PasswordNote1: '8 til 24 tegn',
    PasswordNote2: 'Skal indeholde store og små bogstaver, tal og speciel tegn',
    PasswordNote3: 'Tilladte speciel tegn',
    RepeatPasswordNote: 'Denne adgangskode skal matche den først angivede',

    ChooseAPackage: 'Vælg et spil',
    ChooseACountry: 'Vælg et land',
    Country: 'Land',

    DeployedIn: 'Serveren vil være tilgængelig indenfor ca',

    CPUUsage: 'CPU Forbrug',
    RAMUsage: 'RAM Forbrug',
    DiskUsage: 'Disk Forbrug',
    DiskSpace: 'Disk Plads',

    Validated: 'Konto er valideret',
    DiscordValidation: 'Validering af discord konto',
    UpdateSettings: 'Opdater indstillinger',
    DiscordUsername: 'Discord brugernavn',

    NoWikipediaSelected: 'Der er ikke valgt nogen wikipedia endnu, vælg en for at komme igang',
    SelectWikipedia: 'Vælg en wikipedia',
    Wiki: 'Wiki',
    Wikipedia: 'Guides',
    WikipediaPage: 'Server opsætning guides',
    Topic: 'Emne',
    Anwser: 'Svar',
    Created: 'Oprettet',
    Message: 'Besked',
    Messages: 'Beskeder',
    MessageSent: 'Beskeden blev sendt',
    Tickets: 'Tickets',
    SendTicket: 'Send besked',
    ViewSupportTicket: 'Support Ticket',
    ViewSupportTickets: 'Se Support Tickets',
    WhatProblems: 'Beskriv hvilke problemer du oplever',

    RenewConfig: 'Fornyelses Konfiguration',
    RenewServer: 'Forny Server',

    SERVERPAYMENT: 'Server Betaling',
    ACCOUNTCHARGE: 'Konto Påfyldning',

    EasyToUse: 'Nemt at bruge',
    EasyToUseTitle: 'Brug din tid på at spille',
    EasyToUseText: 'Hos os har du mulighed for nemt selv at ændre din opsætning, og med vores utrolige brugervenlige kontrol panel, er det en leg at styre din server. Du kan nemt lave automatiserede jobs, ændre indstillinger eller starte og stoppe din server med kun få klik. - Du har derfor mulighed for at fokusere på det der er vigtigst, nemlig at game!',
    AddYourFriends: 'Flere brugere',
    AddYourFriendsTitle: 'Få fjælp af dine venner',
    AddYourFriendsText: 'Tilføj selv flere brugere som du kan tildele forskellige roller. Tilføj venner, udviklere eller dine VIP medlemmer, og giv dem mulighed for at styre dele af din server som kun du udvælger. Dette giver dig mulighed for at få hjælp eller være fælles om én server, men husk kun at tilføje personer som du stoler på!',
    EasyFileManager: 'Nemt at ændre filer',
    EasyFileManagerTitle: 'Ændre dine filer nemt og hurtigt',
    EasyFileManagerText: 'Du kan tilføje, slette eller redigere dine server filer direkte i kontrol panelet. Du slipper derfor for at bruge besværlige SFTP programmer, hvis du bare lige hurtigt vil ændre i din servers konfiguration. Hvis du derimod ønsker at bruge SFTP, så har du selvfølgelig også fuld mulighed for dette.',
    WhyChooseUs: 'Hvorfor Os',
    SimplyBecause: 'Simpelthen fordi',

    Next: 'Frem',
    Previous: 'Tilbage',

    /**  
     * 
     * Banners
     * 
     */
    Banner1Title: 'Skynd dig! Og oplev dit livs, måske bedste gaming oplevelse',
    Banner1Desc: 'Det her er din eksklusive chance for at få din helt egen server at spille på! Oplev high-end udstyr og support med vores gaming servere. Men skynd dig – Mens der stadigvæk er nogle ledige!',
    Banner2Title: 'Skynd dig! Og oplev dit livs, måske bedste gaming oplevelse',
    Banner2Desc: 'Det her er din eksklusive chance for at få din helt egen server at spille på! Oplev high-end udstyr og support med vores gaming servere. Men skynd dig – Mens der stadigvæk er nogle ledige!',
    Banner3Title: 'Skynd dig! Og oplev dit livs, måske bedste gaming oplevelse',
    Banner3Desc: 'Det her er din eksklusive chance for at få din helt egen server at spille på! Oplev high-end udstyr og support med vores gaming servere. Men skynd dig – Mens der stadigvæk er nogle ledige!',


    /**  
     * 
     * Packages
     * 
     */
    GBNetwork: '{{ networkspeed }} GB/s netværks opkobling',
    CPUCount: '{{ cpucount }} vCPU op til {{ clockspeed }} Ghz',
    Slots: '{{ slots }} spillere',
    Ticks: '{{ ticks }} Tick',
    GBRAM: '{{ ram }} GB RAM',
    GBDisk: '{{ disk }} GB NVMe SSD',

    DemoHere: 'se demo her',

    LiveMapAvailable: 'Live kort',
    MinecraftGameDescription: 'Minecraft er et overlevelsesspil, hvor der gælder om at overleve med de ressourcer, man har til rådighed. Når man starter et nyt spil, bliver der genereret en ny verden, med alle råstoffer, som findes i Minecraft. Som spiller går det helt simpelt ud på at finde råstoffer, crafte dem om til værktøjer, maskiner og andet, og på den måde gøre det lettere at overleve.',
    MinecraftPackage1Title: 'Glas',
    MinecraftPackage1: 'Til udvikler eller bungeecord server',
    MinecraftPackage2Title: 'Græs',
    MinecraftPackage2: 'Perfekt til en minecraft survival server',
    MinecraftPackage3Title: 'Rødsten',
    MinecraftPackage3: 'Det perfekte valg til minecraft',
    MinecraftPackage4Title: 'Diamand',
    MinecraftPackage4: 'Mere kraft, mere hastighed',
    MinecraftPackage5Title: 'Obsidian',
    MinecraftPackage5: 'Til elite minecraft servers',

    CSGOGameDescription: 'Counter-Strike er en serie af klassiske skydespil, hvor man spiller på to hold. I Counter-Strike: Global Offensive, også kendt som CS:GO eller bare CS, spiller man på hold, hvor terrorister og anti-terrorister, kæmper imod hinanden.',
    CSGOPackage1Title: 'Mini',
    CSGOPackage1: 'If you or your team just wanna play',
    CSGOPackage2Title: 'Small',
    CSGOPackage2: 'If you want to host a custom server',
    CSGOPackage3Title: 'Medium',
    CSGOPackage3: 'For different gamemodes',
    CSGOPackage4Title: 'Large',
    CSGOPackage4: 'Warcraft 3 with db setup',
    CSGOPackage5Title: 'Extra Large',
    CSGOPackage5: 'For those who want the fastest',

    ARKGameDescription: 'Strandet på bredden af en mystisk ø, her skal du lære at overleve. Brug din snedighed til at dræbe eller tæmme oldtids skabninger som dominerer landet, eller røv andre spillere for at overleve, dominere ... eller flygte!',
    ARKPackage1Title: 'Wood',
    ARKPackage1: 'Can only run the basics of ARK',
    ARKPackage2Title: 'Stone',
    ARKPackage2: 'Still basic, but with a little more power',
    ARKPackage3Title: 'Metal',
    ARKPackage3: 'If you want to make a modded server',
    ARKPackage4Title: 'Obsidian',
    ARKPackage4: 'For big maps and lots of build',
    ARKPackage5Title: 'Element',
    ARKPackage5: 'For the extreme ark user',

    VRGameDescription: 'Vågn op som en vampyr. Gå på jagt efter blod i nærliggende bebyggelser for at genvinde din styrke og undgå den brændende sol for at overleve. Opbyg dit slot og udforsk en evigt ændrende verden fuld af mystik. Få allierede online og erobre de levendes land.',
    VRPackage1Title: 'Mini',
    VRPackage1: 'Can only run the basics of ARK',
    VRPackage2Title: 'Small',
    VRPackage2: 'Still basic, but with a little more power',
    VRPackage3Title: 'Medium',
    VRPackage3: 'If you want to make a modded server',
    VRPackage4Title: 'Large',
    VRPackage4: 'For big maps and lots of build',

    RustGameDescription: 'The only aim in Rust is to survive. Everything wants you to die - the island\'s wildlife and other inhabitants, the environment, other survivors. Do whatever it takes to last another night.',
    RustPackage1Title: 'Mini',
    RustPackage1: 'Can only run the basics of Rust',
    RustPackage2Title: 'Small',
    RustPackage2: 'Still basic, but with a little more power',
    RustPackage3Title: 'Medium',
    RustPackage3: 'If you want to make a modded server',
    RustPackage4Title: 'Large',
    RustPackage4: 'For big maps and lots of builds',

    MordhauGameDescription: 'Mordhau is a medieval first & third person multiplayer slasher. Enter a hectic battlefield of up to 64 players as a mercenary in a fictional, but realistic world, where you will get to experience the brutal and satisfying melee combat that will have you always coming back for more.',
    MordhauPackage1Title: 'Mini',
    MordhauPackage1: 'Can only run the basics of Mordhau',
    MordhauPackage2Title: 'Small',
    MordhauPackage2: 'Still basic, but with a little more power',
    MordhauPackage3Title: 'Medium',
    MordhauPackage3: 'If you want to make a modded server',
    MordhauPackage4Title: 'Large',
    MordhauPackage4: 'For big maps and lots of builds',
    
    /**  
     * 
     * TERMS AND SERVICE
     * 
     */
    TermsDescription1: 'Læs venligst disse servicevilkår omhyggeligt, da de vil hjælpe dig med at forstå, hvordan man bruger vores tjenester.',

    Terms1Title: 'Bestilling af Servere',
    Terms1Ordering1: 'En server kan kun bestilles eller fornyes hvis kontoen har tilstrækkelig kredit.',
    Terms1Ordering2: 'En kunde er ikke bundet af noget og kan stoppe med, at spille eller afmelde serveren når som helst, dog mistes brugt kredit.',
    Terms1Ordering3: 'Ordrer vil blive leveret inden for produktets annoncerede tidsramme, medmindre der ikke er nok på lager.',
    Terms1Ordering4: 'Hvis serveren viser sig at være udsolgt, vil den blive leveret inden for 8 dage, ellers vil dine penge blive refunderet.',
    Terms1Ordering5: '{{ name }} vil forsøge at sikre, at der altid er servere nok, men tager forbehold for eventuelle leverandør problemer.',

    Terms2Title: 'Håndtering af Servere',
    Terms2Gameserver1: 'Hvis serveren ikke fornyes, har {{ name }} tilladelse til, at stoppe serveren efter den er udløbet.',
    Terms2Gameserver2: 'Hvis serveren ikke fornyes, har {{ name }} tilladelse til, at slette serveren og alt dens indhold, 3 dage efter den er udløbet.',
    Terms2Gameserver3: 'Support angående plugins/ressourcer og kode er ikke garanteret til alle spil.',
    Terms2Gameserver4: '{{ name }} er ikke ansvarlig i tilfælde af datatab eller korrupte spil filer. Kunden er selv ansvarlig for at lave backups.',

    Terms3Title: 'Kunde Support',
    Terms3Support1: 'Support skal ske via vores discord eller ved, at sende en e-mail til {{ mail }}.',
    Terms3Support2: '{{ name }} bestræber sig på at besvare support henvendelser inden for 24 timer. Men de kan blive besvaret meget hurtigere.',
    Terms3Support3: '{{ name }} tilbyder kun support på de tjenester, der leveres af {{ name }}.',
    Terms3Support4: '{{ name }} yder ikke support til tredjeparts programmer eller plugins.',

    Terms4Title: 'Server Refundering',
    Terms4Refounds1: 'Ved at afgive en ordre accepterer kunden at {{ name }} ikke accepterer nogen anmodning om tilbagebetaling, der ikke er dækket af vores tilbagebetalingspolitik.',
    Terms4Refounds2: 'Kunden er berettiget til refusion, hvis tjenesterne fra {{ name }} har en tilgængelighed på mindre end 99 %, forårsaget af tekniske problemer fra vores side.',
    Terms4Refounds3: 'Nedetid ikke forårsaget af {{ name }} såsom en fejlkonfiguration foretaget af kunden selv tæller ikke med.',
    Terms4Refounds4: 'En kunde bedes rapportere eventuelle fejl og give {{name}} tid til, at udbedre dem før, at en refundering kan blive vurderet.',
    Terms4Refounds5: 'En tilbagebetaling vil blive afvist, hvis årsagen for tilbagebetalingen er: \'Jeg ved ikke hvordan det her virker\'. Kunden er selv ansvarlig for installation og konfiguration af eventuelle programmer eller plugins på sin server.',
    Terms4Refounds6: 'En tilbagebetaling vil blive behandlet inden for 7 hverdage.',
    Terms4Refounds7: 'Ved at afgive en ordre accepterer kunden, at give afkald på sin 14 dages fortrydelsesret, da dette er en service der træder i kræft med det samme.',
    Terms4Refounds8: 'Kunden kan ikke refundere betalingen gennem betalingstjenester såsom paypal ved, at indsende et krav. De skal altid anmode om refusion via {{ name }}\'s support.',
    Terms4Refounds9: 'Hvis kunden åbner et krav med paypal eller åbner en blokering hos kreditkortudbyderen, kan {{ name }} straks stoppe og slette kundens servere uden varsel.',

    Terms5Title: 'Andre Forhold',
    Terms5OtherObligations1: '{{ name }} er ikke ansvarlig for indhold der hostes på købte servere.',
    Terms5OtherObligations2: '{{ name }} kan til hver en tid fjerne ulovligt indhold fra en server, eller hvis det er i strid med anden lovgivning.',
    Terms5OtherObligations3: '{{ name }} er ikke ansvarlig for datatab i noget omfang.',

    Terms6Title: 'Særlige Forhold',
    Terms6ForceOf1: 'Ingen af parterne er forpligtet til at opfylde nogen forpligtelse, hvis den er forhindret heri på grund af force majeure. Force majeure betyder i alle tilfælde: krig (fare), optøjer, strejker, krig, brand, vandskade, oversvømmelser, atmosfæriske forhold, længerevarende strømafbrydelser, justeringer eller vedligeholdelse af andres telenet og/eller elnet, kabelbrud, angreb på et netværk/server inklusive DDoS og DoS, forstyrrelser i netværkene af betydning for {{ name }}, afbrydelser i kommunikative forbindelser, herunder telekommunikationsforbindelser eller forebyggelse eller afvisning/langvarig manglende overholdelse fra leverandører som {{ name }} er afhængig af for, at kunne levere sin service.',

    Terms7Title: 'Konto Kredit',
    Terms7Credits1: '{{ name }} tilbyder optankning af kredit, så der ikke betales unøvendigt for et produkt der ikke bruges.',
    Terms7Credits2: 'Når en konto er optanket med kredit nok til et produkt, kan dette købes og konfigureres.',
    Terms7Credits3: 'Er der ikke mere kredit, kan der blot tilføjes mere uden ekstra omkostninger.',

    Terms8Title: 'Ændringer i Servicevilkår',
    Terms8Changes1: '{{ name }} kan til hver en tid ændre i servicevilkårne, når det er nødvendigt.',
    Terms8Changes2: '{{ name }} skal oplyse om ændringer i vilkår, enten på hjemmesiden eller i discord serveren.',


    /**  
     * 
     * PRIVACY POLICY
     * 
     */
    PrivacyPolicyDescription1: 'Tak, fordi du valgte at være en del af {{name}}.',
    PrivacyPolicyDescription2: 'Vi er forpligtet til at beskytte dine personlige oplysninger og din ret til privatliv.',
    PrivacyPolicyDescription3: 'Hvis du har spørgsmål eller bekymringer vedrørende denne fortrolighedserklæring eller vores praksis med hensyn til dine personlige oplysninger, bedes du kontakte os på discord eller via email {{email}}.',
    PrivacyPolicyDescription4: 'Når du besøger vores hjemmeside https://{{site}} eller bruger nogen af vores andre tjenester, sætter vi pris på, at du stoler på os med dine personlige oplysninger. Vi tager dit privatliv meget alvorligt. I denne fortrolighedserklæring søger vi at forklare dig på den klarest mulige måde, hvilke oplysninger vi indsamler, hvordan vi bruger dem, og hvilke rettigheder du har i forhold til dem. ',
    PrivacyPolicyDescription5: 'Vi håber du tager dig tid til at læse den grundigt igennem, da den er vigtig. Hvis der er vilkår i denne fortrolighedserklæring, som du ikke er enig i, bedes du straks afbryde brugen af vores tjenester.',
    PrivacyPolicyDescription6: 'Denne fortrolighedserklæring gælder for alle oplysninger, der indsamles gennem vores tjenester (som, som beskrevet ovenfor, inkluderer vores websted), samt alle relaterede tjenester, salg, markedsføring eller begivenheder.',
    PrivacyPolicyDescription7: 'Læs venligst denne fortrolighedserklæring omhyggeligt, da den vil hjælpe dig med at forstå, hvad vi gør med de oplysninger som vi indsamler omkring dig.',

    Privacy1Title: 'Hvilke oplysninger indsamler vi om dig ?',
    Privacy1Description1: 'Som udgangspunkt indsamler vi de personlige oplysninger, som du selv videregiver til os.',
    Privacy1Description2: 'Vi indsamler de person oplysninger som du indtaster ved oprettelse af din konto, eller når du tilmelder dig en af vores andre tjenester.',
    Privacy1Description3: 'Personlige oplysninger som vi indsamler afhænger af, dine interaktioner med vores hjemmeside, de valg du træffer, de produkter og funktioner du bruger eller opretter.',
    Privacy1Description4: 'De oplysninger vi indsamler omkring dig, kan omfatte:',
    Privacy1Description5: 'Oplysninger så som e-mailadresser, brugernavne, adgangskoder, ip-adresser og andre lignende oplysninger.',
    Privacy1Description6: 'Alle personlige oplysninger, som du giver os, skal være sande og nøjagtige, dvs. ingen kælenavne, øgenavne eller slang og du skal underrette os om enhver ændring af dine personlige oplysninger.',
    Privacy1Description7: 'Vi indsamler automatisk visse oplysninger, når du besøger, bruger eller navigerer på hjemmesiden eller i nogen af vores andre tjenester. Disse oplysninger afslører ikke din specifikke identitet (såsom dit navn eller kontaktoplysninger), men kan omfatte enheds- og brugsoplysninger, såsom din IP-adresse, browser- og enhedsegenskaber, operativsystem, sprogpræferencer, henvisnings-URL\'er, enhedsnavn, land, placering, information om hvordan og hvornår du bruger vores hjemmeside og andre tekniske oplysninger. Disse oplysninger er primært nødvendige for at opretholde sikkerheden og driften af vores hjemmeside og til vores interne analyse- og rapporteringsformål.',

    Privacy2Title: 'Hvordan bruger vi dine oplysningerne ?',
    Privacy2Description1: '',
    Privacy2Description2: '',
    Privacy2Description3: '',
    Privacy2Description4: '',
    Privacy2Description5: '',

    Privacy3Title: 'Vil oplysningerne blive delt med nogen ?',
    Privacy3Description1: '',
    Privacy3Description2: '',
    Privacy3Description3: '',
    Privacy3Description4: '',
    Privacy3Description5: '',

    Privacy4Title: 'Hvem deler vi dine oplysningerne med ?',
    Privacy4Description1: '',
    Privacy4Description2: '',
    Privacy4Description3: '',
    Privacy4Description4: '',
    Privacy4Description5: '',

    Privacy5Title: 'Bruger vi cookies eller andre teknologier ?',
    Privacy5Description1: 'Kort sagt: Ja, ligesom mange andre virksomheder indsamler vi også oplysninger gennem cookies og lignende teknologier.',
    Privacy5Description2: 'Log- og brugsdata er servicerelaterede, diagnostiske, brugs- og ydeevneoplysninger, som vores servere automatisk indsamler, når du tilgår eller bruger vores hjemmeside og som vi registrerer i logfiler.',
    Privacy5Description3: 'Afhængigt af, hvordan du interagerer med os, kan disse logdata omfatte din IP-adresse, enhedsoplysninger, browsertype og -indstillinger og oplysninger om din aktivitet på webstedet (såsom dato/tidsstempler, der er forbundet med dit brug, viste sider og filer, søgninger og andre handlinger, du foretager, såsom hvilke funktioner du bruger).',
    Privacy5Description4: 'Vi indsamler enhedsdata såsom oplysninger om din computer, telefon, tablet eller anden enhed, du bruger til at få adgang til hjemmesiden eller en af vores tjenester med.',
    Privacy5Description5: 'Afhængigt af den anvendte enhed kan disse enhedsdata omfatte oplysninger såsom din IP-adresse (eller proxyserver), enheds- og applikationsidentifikationsnumre, placering, browsertype, hardwaremodel, internetudbyder og/eller mobiloperatør, oplysninger om operativsystem og systemkonfiguration.',

    Privacy6Title: 'Hvor længe gemmer vi dine oplysninger ?',
    Privacy6Description1: 'Kort sagt: Vi opbevarer dine oplysninger så længe det er nødvendigt for at opfylde de formål, der er beskrevet i denne fortrolighedserklæring, med mindre andet er påkrævet ved lov.',
    Privacy6Description2: 'Vi opbevarer kun dine personlige oplysninger, så længe det er nødvendigt for de formål, der er angivet i denne fortrolighedserklæring, medmindre en længere opbevaringsperiode er påkrævet eller tilladt ved lov (såsom skat, bogføring eller andre lovkrav). Hvis dette ikke er tilfældet, opbevarer vi ikke personlige oplysninger i længere tid end den periode, hvor brugerne har en konto hos os.',
    Privacy6Description3: 'Når vi ikke har noget løbende legitimt forretningsbehov for at behandle dine personlige oplysninger, vil vi enten slette eller anonymisere sådanne oplysninger, eller, hvis dette ikke er muligt (for eksempel fordi dine personlige oplysninger er blevet opbevaret i backup-arkiver), så vil vi sikkert opbevare dine personlige oplysninger og isolere dem fra enhver yderligere behandling, indtil sletning er mulig.',

    Privacy7Title: 'Hvordan holder vi dine informationer sikre ?',
    Privacy7Description1: 'Kort sagt: Vi sigter mod at beskytte dine personlige oplysninger gennem et system af organisatoriske og tekniske sikkerhedsforanstaltninger.',
    Privacy7Description2: 'Vi har implementeret passende tekniske og organisatoriske sikkerhedsforanstaltninger designet til at beskytte sikkerheden for alle personlige oplysninger, vi behandler. ',
    Privacy7Description3: 'På trods af vores sikkerhedsforanstaltninger og bestræbelser på at sikre dine oplysninger, kan ingen elektronisk transmission over internettet eller informationslagringsteknologi garanteres at være 100 % sikker, så vi kan ikke love eller garantere, at hackere, cyberkriminelle eller andre uautoriserede tredjeparter ikke vil være i stand til at besejre vores sikkerhed og uretmæssigt indsamle, få adgang til, stjæle eller ændre dine oplysninger.',
    Privacy7Description4: 'Selvom vi vil gøre vores bedste for at beskytte dine personlige oplysninger, er overførsel af personlige oplysninger til og fra vores hjemmeside på din egen risiko. Du bør kun få adgang til webstedet i et sikkert og virus-frit miljø hvor kun du har adgang.',

    Privacy8Title: 'Hvad er dine rettigheder ?',
    Privacy8Description1: 'Kort sagt: I nogle regioner, såsom Det Europæiske Økonomiske Samarbejdsområde, har du rettigheder, der giver dig større adgang til og kontrol over dine personlige oplysninger. Du kan til enhver tid gennemgå, ændre eller lukke din konto.',
    Privacy8Description2: 'I nogle regioner (såsom Det Europæiske Økonomiske Samarbejdsområde) har du visse rettigheder i henhold til gældende databeskyttelseslove. Disse kan omfatte retten (i) til at anmode om adgang og få en kopi af dine personlige oplysninger, (ii) til at anmode om berigtigelse eller sletning; (iii) at begrænse behandlingen af dine personlige oplysninger; og (iv) hvis relevant, til dataportabilitet.',
    Privacy8Description3: 'Under visse omstændigheder kan du også have ret til at gøre indsigelse mod behandlingen af dine personoplysninger. For at fremsætte en sådan anmodning skal du bruge kontaktoplysningerne nedenfor. Vi vil overveje og handle efter enhver anmodning i overensstemmelse med gældende databeskyttelseslove.',
    Privacy8Description4: 'Hvis vi er afhængige af dit samtykke til at behandle dine personoplysninger, har du til enhver tid ret til at trække dit samtykke tilbage. Bemærk dog, at dette ikke vil påvirke lovligheden af behandlingen før dens tilbagetrækning, og det vil heller ikke påvirke behandlingen af dine personlige oplysninger, der udføres i afhængighed af andre lovlige behandlingsgrunde end samtykke.',
    Privacy8Description5: 'Hvis du er bosat i Det Europæiske Økonomiske Samarbejdsområde, og du mener, at vi ulovligt behandler dine personoplysninger, har du også ret til at klage til din lokale databeskyttelsestilsynsmyndighed. Du kan finde deres kontaktoplysninger her:',
    Privacy8Description6: 'http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm',
    Privacy8Description7: 'Hvis du er bosiddende i Schweiz, er kontaktoplysningerne til databeskyttelsesmyndighederne tilgængelige her:',
    Privacy8Description8: 'https://www.edoeb.admin.ch/edoeb/en/home.html',
    Privacy8Description9: 'Hvis du har spørgsmål eller kommentarer til dine privatlivsrettigheder, kan du sende en e-mail til os på {{email}}.',
    Privacy8Description10: 'Hvis du til enhver tid gerne vil gennemgå eller ændre oplysningerne på din konto eller afslutte din konto, kan du: Kontakt os ved hjælp af de angivne kontaktoplysninger. Efter din anmodning om at lukke din konto, vil vi deaktivere eller slette din konto og oplysninger fra vores aktive databaser. Vi kan dog beholde nogle oplysninger i vores filer for at forhindre svindel, fejlfinde problemer, hjælpe med eventuelle undersøgelser, håndhæve vores brugsbetingelser og/eller overholde gældende lovkrav.',
    Privacy8Description11: 'Cookies og lignende teknologier: De fleste webbrowsere er indstillet til at acceptere cookies som standard. Hvis du foretrækker det, kan du normalt vælge at indstille din browser til at fjerne cookies og til at afvise cookies. Hvis du vælger at fjerne cookies eller afvise cookies, kan dette påvirke visse funktioner eller tjenester på vores hjemmeside. Besøg http://www.aboutads.info/choices/ for at fravælge interessebaseret annoncering på vores websted.',
    Privacy8Description12: 'Fravalg af e-mail-marketing: Du kan til enhver tid afmelde vores marketing-e-mail-liste ved at klikke på afmeld-linket i de e-mails, vi sender, eller ved at kontakte os ved at bruge oplysningerne nedenfor. Du vil derefter blive fjernet fra marketing-e-mail-listen - dog kan vi stadig kommunikere med dig, for eksempel for at sende dig servicerelaterede e-mails, der er nødvendige for administrationen og brugen af ​​din konto, for at svare på serviceanmodninger eller til andre ikke-markedsføringsformål. For ellers at fravælge, kan du: Kontakt os ved hjælp af de angivne kontaktoplysninger.',

    Privacy9Title: 'Opdatere vi privatlivspolitikken ?',
    Privacy9Description1: 'Kort sagt: Ja, vi vil opdatere vores privatlivspolitik efter behov for, at forblive i overensstemmelse med relevante love.',
    Privacy9Description2: 'Vi kan opdatere denne privatlivspolitik når som helst hvis dette kræves.',
    Privacy9Description3: 'Den opdaterede version vil blive angivet med en opdateret "Revideret" dato, og den opdaterede version træder i kraft, så snart den er tilgængelig.',
    Privacy9Description4: 'Hvis vi foretager væsentlige ændringer i denne meddelelse om beskyttelse af personlige oplysninger, kan vi underrette dig enten ved tydeligt at offentliggøre en meddelelse om sådanne ændringer på discord, vores hjemmeside eller ved direkte at sende dig en meddelelse.',
    Privacy9Description5: 'Vi opfordrer dig til at gennemgå denne fortrolighedserklæring ofte for at blive informeret om, hvordan vi beskytter dine oplysninger.',

    Privacy10Title: 'Hvordan kan du få oplyst, ændret eller slettet dine oplysninger ?',
    Privacy10Description1: 'Anmod om at gennemgå, ændre eller slette dine personlige oplysninger ved, at kontakte os på discord eller send en mail til {{email}}.',
    Privacy10Description2: 'Vi svarer på din anmodning om gennemgang, ændring eller sletning af dine oplysninger inden for 30 dage.',

    /**  
     * 
     * INPUT PLACEHOLDERS
     * 
     */
    DiscordValidatePlaceholder: 'Indtast dit discord brugernavn her',


    /**  
     * 
     * SUCCESS MESSAGES
     * 
     */
    SettingsUpdated: 'Indstillingerne blev opdateret',
    TicketHasBeenCreated: 'Din support ticket er blevet oprettet, du vil høre fra os snart',
    YourAccountHasBeenCreated: 'Din bruger er nu blevet oprettet',
    RecoveryLinkSent: 'Gendannelses link sendt',


    /**  
     * 
     * WARNING MESSAGES
     * 
     */
    DeletesIn: 'Ellers slettes den desværre om',
    RenewServerMsg: 'Her kan du ændre serverens indstillinger inden du fornyer den igen, indstillingerne træder først i kraft når betalingen er gennemført',
    NeedsToBeExactUsername: 'Skal være et gyldigt discord brugernavn for at virke',
    PendingDiscordVerification: 'Sådan! Nu mangler du bare at klikke på ikonet i vores discord kanal',
    OnlyChangeIfYouNeed: 'Udfyld kun, hvis du ønsker dette ændret',
    NoSupportTicketsFound: 'Du har ingen support tickets oprettet',
    TicketIsClosed: 'Denne support ticket er blevet lukket',
    ServerMissingPayment: 'Serveren mangler betaling, gør noget',
    AreYouSure: 'Er du helt sikker',
    WantToDeleteServer: 'Du er igang med at slette denne server, alt data vil blive slettet og dit resterende abonnoment vil gå tabt uden refusion. Er du sikker på at du vil udføre dette ?',


    /**  
     * 
     * ERROR MESSAGES
     * 
     */
    WrongEmailOrPassword: 'Forkert brugernavn eller adgangskode',
    SomethingFailed: 'Der skete en fejl, prøv at opdater siden',
    PasswordRecoveryComplete: 'Adgangskoden er nu blevet ændret',
    PasswordNeedsReset: 'Adgangskode ændring mangler',
    TooManyFailedLogins: 'For mange mislykkede forsøg, prøv igen om {{ expiresIn }}',
    TheAccountIsNowActivated: 'Kontoen er aktiveret, du kan nu logge ind',
    AccountNeedsActivation: 'Kontoen mangler aktivering',
    CouldNotCheckOutFromPayPal: 'Kunne ikke færdiggøre betalingen fra paypal',
    CouldNotConnectToPayPal: 'Kunne ikke få forbindelse til paypal',
    PageNotFound: 'Siden blev ikke fundet',
    PageNotFoundMsg: 'Vi beklager, men siden du leder efter er enten blevet flyttet, eller eksistere desværre ikke mere',
    NotEnoughToProceed: 'Ikke nok til at fortsætte, prøv igen',
    NotEnoughCredit: 'Du har ikke nok penge på kontoen',
    ServerSuspended: 'Denne server er midlertidig blevet låst',
    NoServersFound: 'Du har ingen servere tilknyttet',
    ContactSupport: 'Kontakt support',
    AcceptOurAgreement: 'Accepter vores betingelser først',
    PaymentFailed: 'Betalingen fejlede',
    ThisProductIsSoldOut: 'Dette produkt er desværre allerede udsolgt',
    ThisIsNotAnOption: 'Dette er ikke en mulighed',
    NoServerResponse: 'Ingen svar fra serveren',
    PasswordDoesNotMatch: 'Adgangskoderne er ikke ens',
    MissingPassword: 'Mangler adgangskode',
    MissingEmail: 'Mangler email',
    MissingEmailAndPassword: 'Mangler email eller adgangskode',
    EmailAlreadyInUse: 'Email er allerede i brug',
    Unauthorized: 'Ingen adgang',
    ThereWasAnError: 'Der skete en fejl',
    RecoveringFailed: 'Gendannelse fejlede',
    RegistrationFailed: 'Registreringen fejlede',
    LoginFailed: 'Log ind fejlede',
    InvalidEntry: 'Ugyldigt indtastning',
    InvalidToken: 'Ugyldig token, opdater siden',
    RecoverySentToAccount: 'Et gendannelses link er blevet sendt til din email',
    NoPaymentsToShow: 'Der er ingen betalinger at vise'
};