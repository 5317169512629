export const TRANSLATIONS_EU = {

    /**  
     * 
     * Site Settings
     * 
     */
    DateFormat: '{{date, DD.MM.YYYY}}',
    CurrencyFormat: '€ {{currency}}',

    JoinOurDiscord: 'Join Our Discord',
    JoinOurFacebook: 'Join Our Facebook',
    SiteSettings: 'Site Settings',
    LoadingPayment: 'Loading Payment...',
    LoadingServers: 'Loading servers...',
    LoadingContent: 'Loading content...',
    Loading: 'Loading...',

    /**  
     * 
     * Site Language
     * 
     */
    Yes: 'Yes',
    No: 'No',
    You: 'You',
    From: 'From',
    Profile: 'Profile',
    Payments: 'Payments',
    Settings: 'Settings',
    Support: 'Support',
    Logout: 'Sign out',
    Login: 'Login',
    LoginToPay: 'Login in to pay',
    AvailableGames: 'Available Games',
    ConfigurationServer: 'Server Configuration',
    ConfigurationOptions: 'Configuration Options',
    Configuration: 'Configuration',
    ClientArea: 'Client Area',
    GameServers: 'Game Servers',
    ChooseAServerName: 'Choose a server name',
    ChooseGameType: 'Choose a game type',
    ChooseServerType: 'Choose server type',
    GameType: 'Game Type',
    ServerName: 'Server name',
    PanelName: 'Panel name',
    AllServers: 'All servers',
    ServerStatus: 'Server Status',
    OrderNow: 'Order now',
    ServersFrom: 'Servers from',
    ServerSettings: 'Server Settings',
    AMonth: 'Month',
    PrMonth: 'Pr Month',
    MonthlySubscription: 'Monthly subscription',
    FirstMonth: 'First Month',
    Invoice: 'Receipt',
    CreditBefore: 'Credit before',
    CreditLeft: 'Credit left after buy',
    Account: 'Account',
    ChargeAccount: 'Not enough credit',
    ChargeHere: 'Charge Here',
    ChargeCreditHere: 'Charge credit here',
    ChargeCreditHereMsg: 'Here you can charge credit to your account',
    PaymentMethods: 'Payment methods',
    ChargeAmount: 'Charge amount',
    YourCredit: 'Your credit',
    Credit: 'Credit before buy',
    StockLeft: 'Server left',
    StockLeft_Plural: 'Servers left',
    ForgotMsg: 'Forgot your password ?',
    ForgotBtn: 'Send recovery',
    DoesNotExist: 'Does not exist',
    DoesNotExistMsg: 'This product does not exist, try to view our available game servers from the main menu tab',
    DiscountMsg: 'OFF',
    Phone: 'Phone',
    Timezone: 'Timezone',
    Updates: 'Updates',
    UserMenu: 'User menu',
    ServerPanel: 'Server panel',
    Unothorized: 'Unothorized',
    Amount: 'Amount',
    Date: 'Date',
    Status: 'Status',
    CreateOne: 'Create one',
    CreateTicket: 'Create Ticket',
    CreateSupportTicket: 'Create Support Ticket',
    DifferentGames: 'Different Games',
    HostedServers: 'Hosted Servers',
    HappyCustomers: 'Happy Customers',
    StartYourServerNow: 'Start your server now',
    ItemsPrPage: 'Pr Page',
    SharedHosting: 'Shared Hosting',
    ServerLocation: 'Server Location',

    Total: 'Total',
    Tax: 'Tax',
    Price: 'Price',
    Payment: 'Payment',
    BuyServer: 'Buy Server',
    PayHere: 'Pay here',
    Included: 'Included',
    Extra: 'Extra',
    Available: 'Available',
    Options: 'Options',

    DDoSProtection: 'DDoS Protection',
    CPUUsed: 'AMD Ryzen 5 3600',
    OneClickSetup: '1 Click Setup',
    InstantDelivery: 'Instant Delivery',
    Packages: 'Packages',

    FAQ: 'F.A.Q',
    FrequentlyAskedQuestions: 'Frequently Asked Questions',
    TermsOfService: 'Terms Of Service',
    PrivacyPolicy: 'Privacy Policy',
    UsefulLinks: 'Useful Links',
    SiteDescription: 'We are small game hosting company, that delivers blazing fast game servers, for you or your community. Built for games, by gamers.',

    Home: 'Home',
    Overview: 'Overview',

    SignInWidth: 'Sign in with',
    SignUpWidth: 'Sign up with',
    Or: 'or',

    Email: 'Email',
    Username: 'Username',
    Password: 'Password',
    NewPassword: 'New Password',
    RepeatPassword: 'Repeat Password',
    ChangePassword: 'Change password',
    ChangeYourPassword: 'Change your password',
    RememberMe: 'Remember me',
    ForgotPassword: 'Forgot password',
    Register: 'Register',
    RegisterHere: 'Register here',
    NotAMember: 'Not a member',
    SignIn: 'Log in',
    
    AgreeTermsStart: 'I have read and agree to the',
    AgreeTermsEnd: 'And the',

    EmailNote1: '3 to 30 characters.',
    EmailNote2: 'Letters, numbers, underscores, hyphens allowed',
    PasswordNote1: '8 to 24 characters',
    PasswordNote2: 'Must include uppercase and lowercase letters, a number and a special character',
    PasswordNote3: 'Allowed special characters',
    RepeatPasswordNote: 'Must match the first password input field',

    ChooseAPackage: 'Choose a package',
    ChooseACountry: 'Choose a country',
    Country: 'Country',

    DeployedIn: 'Deployed in',

    CPUUsage: 'CPU Usage',
    RAMUsage: 'RAM Usage',
    DiskUsage: 'Disk Usage',
    DiskSpace: 'Disk Space',

    Validated: 'Validated',
    DiscordValidation: 'Discord validation',
    UpdateSettings: 'Update settings',
    DiscordUsername: 'Discord username',

    NoWikipediaSelected: 'No wikipedia selected',
    SelectWikipedia: 'Select a wikipedia',
    Wiki: 'Wiki',
    Wikipedia: 'Wikipedia',
    WikipediaPage: 'Server opsætning guides',
    Topic: 'Topic',
    Anwser: 'Anwser',
    Created: 'Created',
    Message: 'Message',
    Messages: 'Messages',
    MessageSent: 'Message sent',
    Tickets: 'Tickets',
    SendTicket: 'Send ticket',
    ViewSupportTicket: 'View support ticket',
    ViewSupportTickets: 'View support tickets',
    WhatProblems: 'What problems are you experiencing',

    RenewConfig: 'Renew Configuration',
    RenewServer: 'Renew Server',

    SERVERPAYMENT: 'Server Payment',
    ACCOUNTCHARGE: 'Account Charge',

    EasyToUse: 'Easy To Use',
    EasyToUseTitle: 'Spend More Time Playing',
    EasyToUseText: 'Effortlessly manage your game server with our easy-to-use control panel, designed with beginners in mind. Keep an eye on server activity, enter commands, see server settings, and start or stop gameplay with just a few clicks.',
    AddYourFriends: 'Add Your Friends',
    AddYourFriendsTitle: 'Get Help With Your Server',
    AddYourFriendsText: 'Enlist others to your server console to help manage your game server. Add your friends, developers, and staff members, granting them the ability to restart the server and configure files.',
    EasyFileManager: 'Easy File Manager',
    EasyFileManagerTitle: 'Modify Files Easily',
    EasyFileManagerText: 'You can add, delete, and edit files directly on your server. There\'s no need to use a time-consuming SFTP program when you want to quickly edit a config on your game server.',
    WhyChooseUs: 'Why Choose Us',
    SimplyBecause: 'Simply Because',

    Next: 'Next',
    Previous: 'Tilbage',

    /**  
     * 
     * Banners
     * 
     */
    Banner1Title: 'Hurry! Unlock Your Gaming Experience\'s Full Potential!',
    Banner1Desc: 'This is your exclusive chance to get your own server! Experience top-tier performance and support with our game servers. Act fast – spots are filling up quickly. Don\'t miss out!',
    Banner2Title: 'Hurry! Unlock Your Gaming Experience\'s Full Potential!',
    Banner2Desc: 'This is your exclusive chance to get your own server! Experience top-tier performance and support with our game servers. Act fast – spots are filling up quickly. Don\'t miss out!',
    Banner3Title: 'Hurry! Unlock Your Gaming Experience\'s Full Potential!',
    Banner3Desc: 'This is your exclusive chance to get your own server! Experience top-tier performance and support with our game servers. Act fast – spots are filling up quickly. Don\'t miss out!',


    /**  
     * 
     * Packages
     * 
     */
    GBNetwork: 'Up to {{ networkspeed }} GB/s ethernet uplinks',
    CPUCount: '{{ cpucount }}v up to {{ clockspeed }}Ghz',
    Slots: '{{ slots }} slots',
    Ticks: '{{ ticks }} Tick',
    GBRAM: '{{ ram }} GB RAM',
    GBDisk: '{{ disk }} GB NVMe SSD',

    DemoHere: 'demo here',

    LiveMapAvailable: 'Live map available',
    MinecraftGameDescription: 'Minecraft is a video game in which players create and break apart various kinds of blocks in three-dimensional worlds. The game\'s two main modes are Survival and Creative. In Survival, players must find their own building supplies and food. They also interact with blocklike mobs, or moving creatures.',
    MinecraftPackage1Title: 'Glass',
    MinecraftPackage1: 'Development server or Bungeecord',
    MinecraftPackage2Title: 'Grass',
    MinecraftPackage2: 'Perfect for a Minecraft Survival Server',
    MinecraftPackage3Title: 'Redstone',
    MinecraftPackage3: 'Det perfekte valg til Minecraft',
    MinecraftPackage4Title: 'Diamond',
    MinecraftPackage4: 'More power, more performance',
    MinecraftPackage5Title: 'Obsidian',
    MinecraftPackage5: 'For elite Minecraft servers',

    CSGOGameDescription: 'The game pits two teams, Terrorists and Counter-Terrorists, against each other in different objective-based game modes. The most common game modes involve the Terrorists planting a bomb while Counter-Terrorists attempt to stop them, or Counter-Terrorists attempting to rescue hostages that the Terrorists have captured.',
    CSGOPackage1Title: 'Mini',
    CSGOPackage1: 'If you or your team just wanna play',
    CSGOPackage2Title: 'Small',
    CSGOPackage2: 'If you want to host a custom server',
    CSGOPackage3Title: 'Medium',
    CSGOPackage3: 'For different gamemodes',
    CSGOPackage4Title: 'Large',
    CSGOPackage4: 'Warcraft 3 with db setup',
    CSGOPackage5Title: 'Extra Large',
    CSGOPackage5: 'For those who want the fastest',

    ARKGameDescription: 'Players are stranded on a lush tropical island inhabited by mammoth dinosaurs. Survive by scavenging resources to craft tools and build a home to withstand the elements. Pick seeds and plant them, collecting dinosaur excrement to fertilize your farm.',
    ARKPackage1Title: 'Wood',
    ARKPackage1: 'Can only run the basics of ARK',
    ARKPackage2Title: 'Stone',
    ARKPackage2: 'Still basic, but with a little more power',
    ARKPackage3Title: 'Metal',
    ARKPackage3: 'If you want to make a modded server',
    ARKPackage4Title: 'Obsidian',
    ARKPackage4: 'For big maps and lots of build',
    ARKPackage5Title: 'Element',
    ARKPackage5: 'For the extreme ark user',

    VRGameDescription: 'Awaken as a vampire. Hunt for blood in nearby settlements to regain your strength and evade the scorching sun to survive. Raise your castle and thrive in an ever-changing open world full of mystery. Gain allies online and conquer the land of the living.',
    VRPackage1Title: 'Mini',
    VRPackage1: 'Can only run the basics of ARK',
    VRPackage2Title: 'Small',
    VRPackage2: 'Still basic, but with a little more power',
    VRPackage3Title: 'Medium',
    VRPackage3: 'If you want to make a modded server',
    VRPackage4Title: 'Large',
    VRPackage4: 'For big maps and lots of build',

    RustGameDescription: 'The only aim in Rust is to survive. Everything wants you to die - the island\'s wildlife and other inhabitants, the environment, other survivors. Do whatever it takes to last another night.',
    RustPackage1Title: 'Mini',
    RustPackage1: 'Can only run the basics of Rust',
    RustPackage2Title: 'Small',
    RustPackage2: 'Still basic, but with a little more power',
    RustPackage3Title: 'Medium',
    RustPackage3: 'If you want to make a modded server',
    RustPackage4Title: 'Large',
    RustPackage4: 'For big maps and lots of builds',

    MordhauGameDescription: 'Mordhau is a medieval first & third person multiplayer slasher. Enter a hectic battlefield of up to 64 players as a mercenary in a fictional, but realistic world, where you will get to experience the brutal and satisfying melee combat that will have you always coming back for more.',
    MordhauPackage1Title: 'Mini',
    MordhauPackage1: 'Can only run the basics of Mordhau',
    MordhauPackage2Title: 'Small',
    MordhauPackage2: 'Still basic, but with a little more power',
    MordhauPackage3Title: 'Medium',
    MordhauPackage3: 'If you want to make a modded server',
    MordhauPackage4Title: 'Large',
    MordhauPackage4: 'For big maps and lots of builds',
    
    /**  
     * 
     * TERMS AND SERVICE
     * 
     */
    Terms1Title: 'Ordering',
    Terms1Ordering1: 'The customer is not obliged to make the payment after placing an order.',
    Terms1Ordering2: 'The customer is not committed to anything and can stop paying at any time.',
    Terms1Ordering3: 'Orders will be delivered within the advertised indication on our website, unless there is not enough stock.',
    Terms1Ordering4: 'If the server turns out to be out of stock, it will be delivered within 8 days else a refund will be processed.',
    Terms1Ordering5: '{{ name }} will do its utmost to ensure that there is always enough stock.',

    Terms2Title: 'Game server',
    Terms2Gameserver1: 'If the consumer does not renew the server, {{ name }} is authorized to shut down the server after it has been expired.',
    Terms2Gameserver2: 'If the consumer does not renew the server, {{ name }} is authorized to remove all data of this server, 3 days after the server is expired.',
    Terms2Gameserver3: 'Support with regard to plugins/resources and code is not guaranteed.',
    Terms2Gameserver4: '{{ name }} is not responsible in case of data loss. The customer is responsible for making backups.',

    Terms3Title: 'Support',
    Terms3Support1: 'The customer can open a ticket in our Discord server or send us an email to {{ mail }}, if he has any questions regarding his service.',
    Terms3Support2: 'The official (non-SLA) response time of {{ name }} is 24 hours. But in practice this can of course be a lot faster.',
    Terms3Support3: '{{ name }} only offers support on the services provided by {{ name }}.',
    Terms3Support4: '{{ name }} does not provide support for third-party software.',

    Terms4Title: 'Refounds',
    Terms4Refounds1: 'By placing an order, the customer accepts that {{ name }} does not accept any refund request that isn\'t covered by our refund policy.',
    Terms4Refounds2: 'The customer is entitled to a refund if the services of {{ name }} have an availability of less than 99%, caused by technical issues on our side.',
    Terms4Refounds3: 'Downtime not caused by {{ name }} such as a misconfiguration made by the customer does not count.',
    Terms4Refounds4: 'The customer needs to report a technical issue to the {{ name }} support via the #support channel in our discord server and give {{ name }} enough time to fix the issue.',
    Terms4Refounds5: 'A refund will be denied if the reason for the refund is: \'I don\'t know how it works\'. The customer is responsible for installation and configuration of any programs or plugins in his server.',
    Terms4Refounds6: 'A refund will be processed within 7 business days.',
    Terms4Refounds7: 'Some countries have a legal period in which the customer can terminate their server without giving any reason. The customer then gets his money back. This is called Right of withdrawal (or herroepingsrecht). By placing an order with {{ name }}, you agree to waive this right.',
    Terms4Refounds8: 'The customer may not refund the payment, through payment services such as paypal by submitting a claim. They must always request a refund via the {{ name }} support.',
    Terms4Refounds9: 'If the customer opens a claim with paypal or opens a dispute at the creditcard provider, {{ name }} may immediately stop and delete the services of the customer without warning.',


    Terms5Title: 'Other obligations of {{name}}',
    Terms5OtherObligations1: '{{ name }} is not responsible for the content that the customer is hosting.',
    Terms5OtherObligations2: '{{ name }} will remove illegal content or other illegal applications/scripts that are not permitted by law.',
    Terms5OtherObligations3: '{{ name }} is not responsible for data-loss in any case.',

    Terms6Title: 'Force of the majority',
    Terms6ForceOf1: 'None of the parties is obliged to fulfill any obligation if it is prevented from doing so due to force majeure. Force majeure means in any case: war (danger), riots, strikes, war, fire, water damage, flooding, atmospheric conditions, prolonged power outages, adjustments or maintenance to the telecommunications network and / or electricity network of others, cable breaks, attacks on a network / server including DDoS and DoS, disruptions in the networks important for {{ name }}, disruptions in communicative connections including telecommunication connections or prevention or refusal / prolonged absence of compliance by suppliers on whom {{ name }} depends on the performance of its work is.',

    Terms7Title: 'Credits & Donations',
    Terms7Credits1: '{{ name }} provides a donation panel in which the players of your server can donate money.',
    Terms7Credits2: 'For a 1 euro donation you will receive 110 credits (€ 1,10).',
    Terms7Credits3: 'It\'s not allowed to donate credits to yourself.',
    Terms7Credits4: 'Credits can only be used on the {{ name }} website to renew your server, upgrade your server or to buy a new server.',
    Terms7Credits5: 'Credits cannot be refunded.',

    Terms8Title: 'Changes in terms and services',
    Terms8Changes1: '{{ name }} is allowed to change the terms and services at any time.',
    Terms8Changes2: '{{ name }} has to announce every change in the #announcements channel in it\'s Discord server.',


    /**
     * 
     * PRIVACY POLICY
     * 
     */
    Privacy1Title: '',
    Privacy1Description1: '',
    Privacy1Description2: '',
    Privacy1Description3: '',
    Privacy1Description4: '',
    Privacy1Description5: '',

    Privacy2Title: '',
    Privacy2Description1: '',
    Privacy2Description2: '',
    Privacy2Description3: '',
    Privacy2Description4: '',
    Privacy2Description5: '',

    Privacy3Title: '',
    Privacy3Description1: '',
    Privacy3Description2: '',
    Privacy3Description3: '',
    Privacy3Description4: '',
    Privacy3Description5: '',

    Privacy4Title: '',
    Privacy4Description1: '',
    Privacy4Description2: '',
    Privacy4Description3: '',
    Privacy4Description4: '',
    Privacy4Description5: '',

    Privacy5Title: '',
    Privacy5Description1: '',
    Privacy5Description2: '',
    Privacy5Description3: '',
    Privacy5Description4: '',
    Privacy5Description5: '',

    Privacy6Title: '',
    Privacy6Description1: '',
    Privacy6Description2: '',
    Privacy6Description3: '',
    Privacy6Description4: '',
    Privacy6Description5: '',

    Privacy7Title: '',
    Privacy7Description1: '',
    Privacy7Description2: '',
    Privacy7Description3: '',
    Privacy7Description4: '',
    Privacy7Description5: '',

    Privacy8Title: '',
    Privacy8Description1: '',
    Privacy8Description2: '',
    Privacy8Description3: '',
    Privacy8Description4: '',
    Privacy8Description5: '',


    /**  
     * 
     * INPUT PLACEHOLDERS
     * 
     */
    DiscordValidatePlaceholder: 'Enter your discord username here',


    /**  
     * 
     * SUCCESS MESSAGES
     * 
     */
    SettingsUpdated: 'Settings has been updated',
    TicketHasBeenCreated: 'Ticket has been created, you will hear from us soon',
    YourAccountHasBeenCreated: 'Your account have been created, a confirmation link has been sent to your email',
    RecoveryLinkSent: 'A recovery link have been sent to your email',


    /**  
     * 
     * WARNING MESSAGES
     * 
     */
    DeletesIn: 'Deletes in',
    RenewServerMsg: 'This is the configuration for the renewed server, you can change these settings and they will take place when you renew the server',
    NeedsToBeExactUsername: 'Needs to be a valid discord username to work',
    PendingDiscordVerification: 'Now you only need to click the verify reaction in our discord channel',
    OnlyChangeIfYouNeed: 'Only add anything here if you want to change',
    NoSupportTicketsFound: 'You have no support tickets',
    TicketIsClosed: 'Ticket is closed',
    ServerMissingPayment: 'Server missing payment',
    AreYouSure: 'Are you sure',
    WantToDeleteServer: 'Are you sure you want to delete this server ? You will loose all data, and no refound will be given',


    /**  
     * 
     * ERROR MESSAGES
     * 
     */
    WrongEmailOrPassword: 'Wrong email or password',
    SomethingFailed: 'Something failed, try to refresh the page',
    PasswordRecoveryComplete: 'Password recovery complete',
    PasswordNeedsReset: 'Password needs reset',
    TooManyFailedLogins: 'You have failed the login too many times, try again in {{ expiresIn }}',
    TheAccountIsNowActivated: 'The account is activated, you can now sign in',
    AccountNeedsActivation: 'Account needs activation',
    CouldNotCheckOutFromPayPal: 'Could not check out from paypal',
    CouldNotConnectToPayPal: 'Could not connect to PayPal',
    PageNotFound: 'Page was not found',
    PageNotFoundMsg: 'We\'re sorry, the page you are looking for could not be found',
    NotEnoughToProceed: 'Not enough to proceed',
    NotEnoughCredit: 'Not enough credit',
    ServerSuspended: 'This server is suspended!',
    NoServersFound: 'No servers found',
    ContactSupport: 'Contact support',
    AcceptOurAgreement: 'Accept our agreement',
    PaymentFailed: 'Payment failed',
    ThisProductIsSoldOut: 'This product is sold out',
    ThisIsNotAnOption: 'This is not an option',
    NoServerResponse: 'No Server Response',
    PasswordDoesNotMatch: 'Password does not match',
    MissingPassword: 'Missing Password',
    MissingEmail: 'Missing Email',
    MissingEmailAndPassword: 'Missing email or password',
    EmailAlreadyInUse: 'Email already in use',
    Unauthorized: 'Unauthorized',
    ThereWasAnError: 'There was an error',
    RecoveringFailed: 'Recovering Failed',
    RegistrationFailed: 'Registration failed',
    LoginFailed: 'Login Failed',
    InvalidEntry: 'Invalid Entry',
    InvalidToken: 'Invalid token, refresh the page',
    RecoverySentToAccount: 'A recovery link has been sent to your email',
    NoPaymentsToShow: 'No payments to show'
};
