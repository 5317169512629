import { useTranslation } from 'react-i18next';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import * as Icon from 'react-bootstrap-icons';
import { documentHeight } from '../_helpers/Utils';
import Header from '../_components/Header';
import Footer from '../_components/Footer';

const Policy = () => {
  const { t } = useTranslation();

  const legalName = process.env.REACT_APP_LEGAL_NAME;
  const supportMail = process.env.REACT_APP_SUPPORT_EMAIL;
  const legalWebsite = process.env.REACT_APP_BASE_URL.split('//')[1];

  return (
    <>
      <Header />
        <div id="header_static" className="default-bg container-fluid product single top-bg">
          <div className="product_top d-none d-lg-block">
            <h1>{t("PrivacyPolicy").toUpperCase()}</h1>
          </div>

        <section>
          <MDBContainer className="py-4 small">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="content-nav bg-dark p-3 mb-4">
                  <MDBBreadcrumbItem>
                    <a href='/'>{t("Home")}</a>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>{t("PrivacyPolicy")}</MDBBreadcrumbItem>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3" style={{ minHeight: `${documentHeight()}px` }}>
                <MDBCard className="mb-4 mb-lg-0 sticky-top sticky-offset">
                  <MDBCardBody className="p-0" id="scrollspy">
                    <MDBListGroup>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-1" className="d-flex justify-content-between align-items-center">
                          {t("Privacy1Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-2" className="d-flex justify-content-between align-items-center">
                          {t("Privacy2Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-3" className="d-flex justify-content-between align-items-center">
                          {t("Privacy3Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-4" className="d-flex justify-content-between align-items-center">
                          {t("Privacy4Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-5" className="d-flex justify-content-between align-items-center">
                          {t("Privacy5Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-6" className="d-flex justify-content-between align-items-center">
                          {t("Privacy6Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-7" className="d-flex justify-content-between align-items-center">
                          {t("Privacy7Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-8" className="d-flex justify-content-between align-items-center">
                          {t("Privacy8Title")}
                        </a>
                      </MDBListGroupItem>
                      <hr/>
                      <MDBListGroupItem>
                        <a href="/privacy-policy/#privacy-9" className="d-flex justify-content-between align-items-center">
                          {t("Privacy9Title")}
                        </a>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol lg="9">
                <MDBCard className="mb-4">
                  <MDBCardBody
                    data-mdb-spy="scroll"
                    data-mdb-target="#scrollspy"
                    data-mdb-offset="50"
                  >

                    <MDBRow>
                      <MDBCol sm="12" className="text-lightgrey">
                        <p className="mb-0">{t('PrivacyPolicyDescription1', { name: legalName })}</p>

                        <p>{t('PrivacyPolicyDescription2')}</p>

                        <p>{t('PrivacyPolicyDescription3', { email: supportMail })}</p>

                        <p>{t('PrivacyPolicyDescription4', { site: legalWebsite })}</p>

                        <p>{t('PrivacyPolicyDescription5')}</p>

                        <p>{t('PrivacyPolicyDescription6')}</p>

                        <div className="alert alert-default d-flex justify-content-center align-items-center">
                          <Icon.ExclamationTriangleFill className="fs-5 me-2"/> {t('PrivacyPolicyDescription7')}
                        </div>

                        <h3 id="privacy-1" className="pt-3">1. {t("Privacy1Title")}</h3>
                          <ol>
                            <li>{t("Privacy1Description1")}</li>
                            <li>{t("Privacy1Description2")}</li>
                            <li>{t("Privacy1Description3")}</li>
                            <li>{t("Privacy1Description4")}</li>
                            <li className="list-group fw-bolder">{t("Privacy1Description5")}</li>
                            <li>{t("Privacy1Description6")}</li>
                            <li>{t("Privacy1Description7")}</li>
                          </ol>

                        <h3 id="privacy-2" className="pt-3">2. {t("Privacy2Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy2Description1")}</div>
                          <ol>
                            <li>{t("Privacy2Description2")}</li>
                            <li>{t("Privacy2Description3")}</li>
                            <li>{t("Privacy2Description4")}</li>
                            <li>{t("Privacy2Description5")}</li>
                          </ol>

                        <h3 id="privacy-3" className="pt-3">3. {t("Privacy3Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy3Description1")}</div>
                          <ol>
                            <li>{t("Privacy3Description2")}</li>
                            <li>{t("Privacy3Description3")}</li>
                            <li>{t("Privacy3Description4")}</li>
                            <li>{t("Privacy3Description5")}</li>
                          </ol>

                        <h3 id="privacy-4" className="pt-3">4. {t("Privacy4Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy4Description1")}</div>
                          <ol>
                            <li>{t("Privacy4Description2")}</li>
                            <li>{t("Privacy4Description3")}</li>
                            <li>{t("Privacy4Description4")}</li>
                            <li>{t("Privacy4Description5")}</li>
                          </ol>

                        <h3 id="privacy-5" className="pt-3">5. {t("Privacy5Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy5Description1")}</div>
                          <ol>
                            <li>{t("Privacy5Description2")}</li>
                            <li>{t("Privacy5Description3")}</li>
                            <li>{t("Privacy5Description4")}</li>
                            <li>{t("Privacy5Description5")}</li>
                          </ol>

                        <h3 id="privacy-6" className="pt-3">6. {t("Privacy6Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy6Description1")}</div>
                          <ol>
                            <li>{t("Privacy6Description2")}</li>
                            <li>{t("Privacy6Description3")}</li>
                          </ol>

                        <h3 id="privacy-7" className="pt-3">7. {t("Privacy7Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy7Description1")}</div>
                          <ol>
                            <li>{t("Privacy7Description2")}</li>
                            <li>{t("Privacy7Description3")}</li>
                            <li>{t("Privacy7Description4")}</li>
                          </ol>

                        <h3 id="privacy-8" className="pt-3">8. {t("Privacy8Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy8Description1")}</div>
                          <ol>
                            <li>{t("Privacy8Description2")}</li>
                            <li>{t("Privacy8Description3")}</li>
                            <li>{t("Privacy8Description4")}</li>
                            <li>{t("Privacy8Description5")}</li>
                            <li className="list-group fw-bolder">{t("Privacy8Description6")}</li>
                            <li>{t("Privacy8Description7")}</li>
                            <li className="list-group fw-bolder">{t("Privacy8Description8")}</li>
                            <li>{t("Privacy8Description9", { email: supportMail })}</li>
                            <li>{t("Privacy8Description10")}</li>
                            <li>{t("Privacy8Description11")}</li>
                            <li>{t("Privacy8Description12")}</li>
                          </ol>

                        <h3 id="privacy-9" className="pt-3">9. {t("Privacy9Title")}</h3>
                          <div className="alert alert-default d-flex justify-content-between align-items-center">{t("Privacy9Description1")}</div>
                          <ol>
                            <li>{t("Privacy9Description2")}</li>
                            <li>{t("Privacy9Description3")}</li>
                            <li>{t("Privacy9Description4")}</li>
                            <li>{t("Privacy9Description5")}</li>
                          </ol>

                        <h3 id="privacy-10" className="pt-3">10. {t("Privacy10Title")}</h3>
                          <ol>
                            <li>{t("Privacy10Description1", { email: supportMail })}</li>
                            <li>{t("Privacy10Description2")}</li>
                          </ol>
                      </MDBCol>
                    </MDBRow>

                  </MDBCardBody>
                </MDBCard>

              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        
        </div>

      <Footer />
    </>
  )
};

export default Policy;